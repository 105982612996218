/* eslint-disable @typescript-eslint/no-explicit-any */
import { GetServerSideProps } from 'next';

import { PageRendererComponent, prePageCall } from '@marriott/mi-headless-utils';
import OfferSignatures from '../../signatures/offerSignatures.json';
import { updateRobotMetaNames } from '@marriott/mi-offers-components';
import { envVarKeys } from '../../_constants/envVarKeys';

function pageRenderer(props) {
  console.log('[offers.tsx] props ', props);
  if (
    props.productSpecificResponse === undefined &&
    props?.query?.invalidDealMsg === 'true' &&
    props.model?.metaNames
  ) {
    const mappedMetaNames = updateRobotMetaNames(props.model.metaNames);
    props.model['metaNames'] = mappedMetaNames;
  }
  return <PageRendererComponent {...props} />;
}

//Any product specific page data apart from common data like session, datalayer
//can be returned from this function and the same is passed as props to the page Component
const offersSpecificData = async () => {
  return { operationSignatures: OfferSignatures, nextPublicEnvKeys: [...Object.keys(envVarKeys)] };
};

//Make pre page calls like session, datalayer, fetch model.json, producSpecifc data etc
//at server side and pass the data is passed as props to page component.
export const getServerSideProps: GetServerSideProps<any> = prePageCall(offersSpecificData);
export default pageRenderer;
